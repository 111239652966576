import { OsTableColumn, OsTableOption, RowOperation } from '@/components/os-table/os-table';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { messageError, debounce } from '@/utils';
import { installationOrderService } from '@/api';
import { LogisticsOrderStatusEnum } from '@/resource/enum';
import { LogisticsList } from '@/resource/model';

interface LogisticsOrderList {
  index: number;
  expressName: string;
  logisticsNo: string;
  status: number;
}

@Component({
  components: {}
})
export default class LogisticsOrder extends Vue {
  @Prop({
    required: true,
    type: Number,
    default: 0
  })
  public orderId!: number;

  public tableOptions: OsTableOption<LogisticsOrderList> = {
    loading: false,
    data: [],
    fit: true,
    size: 'small',
    border: true,
    closeAdaptiveHeight: true
  };

  public columnOptions: Array<OsTableColumn<LogisticsOrderList>> = [
    {
      prop: 'index',
      label: 'common.index',
      minWidth: '50px',
      showOverflowTooltip: true,
      fixed: true
    },
    {
      prop: 'expressName',
      label: 'orderLogisticsOrder.shippingCompany',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'logisticsNo',
      label: 'orderLogisticsOrder.shippingCode',
      minWidth: '200px',
      showOverflowTooltip: true
    },
    {
      prop: 'status',
      label: 'orderLogisticsOrder.status',
      minWidth: '500px',
      showOverflowTooltip: true
    }
  ];

  /**
   * 表格行操作配置
   */
  public rowOperationOptions: RowOperation<LogisticsOrderList> = {
    fixed: 'right',
    width: '180px',
    operations: [
      {
        operationType: 'edit',
        type: 'text',
        label: 'orderLogisticsOrder.linkToDetails',
        size: 'small',
        handleClick: this.linkToDetails
      }
    ]
  };

  public created(): void {
    this.getLogisticsOrder();
  }
  public getStatusClass(logistics: LogisticsList): string {
    switch (logistics.status) {
      case LogisticsOrderStatusEnum.new:
        return 'info-dot';
      case LogisticsOrderStatusEnum.receivedGoods:
        return 'primary-dot';
      case LogisticsOrderStatusEnum.closed:
        return 'success-dot';
      default:
        return 'danger-dot';
    }
  }

  public getStatusName(logistics: LogisticsList): string {
    if (!LogisticsOrderStatusEnum[logistics.status]) {
      return 'common.unKnownStatus';
    }
    return `logisticsOrderStatus.${LogisticsOrderStatusEnum[logistics.status]}`;
  }

  public linkToDetails(logistics: any): void {
    this.$router.push({
      path: 'logistics-details',
      query: {
        id: logistics.id.toString()
      }
    });
  }

  @debounce()
  public getLogisticsOrder(): void {
    installationOrderService
      .getLogisticsList(this.orderId)
      .then(res => {
        res.data.forEach((item, index) => {
          item.index = index + 1;
        });
        this.tableOptions.data = res.data;
      })
      .catch(error => {
        messageError(error);
      });
  }

  @Watch('orderId', { immediate: true, deep: true })
  private getData(): void {
    this.getLogisticsOrder();
  }
}
