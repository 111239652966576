import { render, staticRenderFns } from "./logistics-order.vue?vue&type=template&id=50e4e24c&scoped=true&"
import script from "./logistics-order.ts?vue&type=script&lang=ts&"
export * from "./logistics-order.ts?vue&type=script&lang=ts&"
import style0 from "./logistics-order.scoped.scss?vue&type=style&index=0&id=50e4e24c&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50e4e24c",
  null
  
)

export default component.exports