import { MessageBoxData, MessageBoxInputData } from 'element-ui/types/message-box';
import { ExceptionRecordList, InstallationOrderInfo, UploadConfigParams } from '@/resource/model';
import { Component, Vue } from 'vue-property-decorator';
import { installationOrderService, minioService } from '@/api';
import { debounce, messageError, messageErrors, showWarningConfirm, translation } from '@/utils';
import { InstallationOrderStatusEnum } from '@/resource/enum';
import { Message, MessageBox } from 'element-ui';
import { UploadFile } from '@/views/dialogs';
import InstallationOrderPhoto from './installation-order-photo/installation-order-photo.vue';
import InstallAttachment from '../../installation-notice/install-attachment/install-attachment.vue';
import ProductDetails from './product-details/product-details.vue';
import ExceptionRecord from './exception-record/exception-record.vue';
import AcceptanceRecord from './acceptance-record/acceptance-record.vue';
import LogisticsOrder from './logistics-order/logistics-order.vue';
import { Route } from 'vue-router/types/router';
import StatusStep from './status-step/status-step.vue';
import { TagsViewModule } from '@/store/modules/tags-view';
Component.registerHooks(['beforeRouteLeave']);
@Component({
  name: 'InstallationOrderDetail',
  components: {
    UploadFile,
    InstallationOrderPhoto,
    InstallAttachment,
    ProductDetails,
    StatusStep,
    ExceptionRecord,
    AcceptanceRecord,
    LogisticsOrder
  }
})
export default class InstallationOrderDetail extends Vue {
  /**
   * 安装单ID
   */
  public id = Number(this.$route.query.id);

  public installationOrder: InstallationOrderInfo | null = null;

  public exceptionList: Array<ExceptionRecordList> = [];

  /**
   *  照片视频上传管理
   */

  public uploadConfigParams: Array<UploadConfigParams> = [
    {
      name: 'unboxingVideo',
      title: '开箱视频',
      getFileMethod: installationOrderService.getUnBoxVideo,
      uploadFileMethod: installationOrderService.uploadUnBoxVideo,
      fileTypeLimit: '.mp4',
      fileSize: 20,
      fileLimit: 1,
      openDelete: false,
      openUpload: true
    },
    {
      name: 'acceptanceSignature',
      title: '验收签名',
      getFileMethod: installationOrderService.getSignPhoto,
      uploadFileMethod: installationOrderService.uploadSignPhoto,
      fileTypeLimit: '.jpg,.jpeg,.png',
      fileSize: 20,
      fileLimit: 1,
      openDelete: false,
      openUpload: true
    },
    {
      name: 'photosBeforeInstallation',
      title: '安装前照片',
      getFileMethod: installationOrderService.getBeforePhoto,
      uploadFileMethod: installationOrderService.uploadBeforePhoto,
      fileTypeLimit: '.jpg,.jpeg,.png',
      fileSize: 20,
      fileLimit: 20,
      openDelete: false,
      openUpload: true
    },
    {
      name: 'photosAfterInstallation',
      title: '安装后照片',
      getFileMethod: installationOrderService.getFinishPhotos,
      uploadFileMethod: installationOrderService.uploadFinishPhotos,
      fileTypeLimit: '.jpg,.jpeg,.png',
      fileSize: 20,
      fileLimit: 50,
      openDelete: true,
      openUpload: true
    },
    {
      name: 'qualificationCertificate',
      title: '资质证照',
      getFileMethod: installationOrderService.getAptitudePhoto,
      uploadFileMethod: installationOrderService.uploadAptitudePhoto,
      fileTypeLimit: '.jpg,.jpeg,.png',
      fileSize: 20,
      fileLimit: 5,
      openDelete: false,
      openUpload: true
    }
  ];

  /**
   * 附件上传dialog显示控制标识
   */
  public uploadDialogVisible = false;

  private needReloadList = false;

  public get title(): string {
    return `${translation('route.installationOrderDetail')}: ${this.installationOrder?.code}`;
  }

  public get address(): string {
    if (this.installationOrder?.province) {
      return `${this.installationOrder?.province}
        ${this.installationOrder?.city ? this.installationOrder?.city : ''}
        ${this.installationOrder?.district ? this.installationOrder?.district : ''}
        ${this.installationOrder?.address}`;
    }
    return '--';
  }

  public get dynamicTags(): Array<string> {
    return this.installationOrder!.installTag ? JSON.parse(this.installationOrder!.installTag) : [];
  }

  public get showApprovalBtn(): boolean {
    return this.installationOrder!.status === InstallationOrderStatusEnum.acceptancePending;
  }

  public get showPushBtn(): boolean {
    return this.installationOrder!.status === InstallationOrderStatusEnum.toBePushed;
  }

  public get showCancelBtn(): boolean {
    return [
      InstallationOrderStatusEnum.PendingOrders,
      InstallationOrderStatusEnum.pendingAppointment,
      InstallationOrderStatusEnum.inService
    ].includes(this.installationOrder!.status);
  }

  public activated(): void {
    this.needReloadList = false;
    // 当id变化时，重新加载数据
    if (!isNaN(this.id) && this.id !== Number(this.$route.query.id)) {
      this.id = Number(this.$route.query.id);
      this.getInstallationInfo(this.id);
      this.getExcept(this.id);
    }
  }

  public created(): void {
    if (!isNaN(this.id)) {
      this.getInstallationInfo(this.id);
      this.getExcept(this.id);
    }
    minioService.init();
  }

  public beforeRouteLeave(to: Route, from: Route, next: Function): void {
    if (to.path === '/installation-order') {
      to.meta!.needReload = this.needReloadList;
    }
    next();
  }

  /**
   * 根据状态获取i18n key
   * @param status 状态值
   * @returns
   */
  public getStatusI18Key(status: InstallationOrderStatusEnum): string {
    return installationOrderService.getStatusI18Key(status);
  }

  public goBack(): void {
    this.$router.back();
  }

  public openUploadDialog(): void {
    this.uploadDialogVisible = true;
  }

  /**
   * 取消
   */
  public cancel(): void {
    showWarningConfirm(translation('installationOrder.confirmCancel'))
      .then(async () => {
        try {
          await installationOrderService.batchCancel([this.id]);
          this.installationOrder!.status = InstallationOrderStatusEnum.completed;
          Message.success(translation('operationRes.operationSuccess'));
          this.needReloadList = true;

          this.$router.go(-1);
          TagsViewModule.DelView(this.$route);
        } catch (error) {
          messageErrors(error as any);
        }
      })
      .catch(() => {
        Message.info(translation('operationRes.cancelOperation'));
      });
  }

  /**
   * 推送
   */
  public push(): void {
    showWarningConfirm(translation('installationOrder.confirmPushOrder'))
      .then(async () => {
        try {
          await installationOrderService.batchPush([this.id]);
          this.installationOrder!.status = InstallationOrderStatusEnum.completed;
          Message.success(translation('operationRes.operationSuccess'));
          this.needReloadList = true;
          this.getInstallationInfo(this.id);
        } catch (error) {
          messageErrors(error as any);
        }
      })
      .catch(() => {
        Message.info(translation('operationRes.cancelOperation'));
      });
  }

  /**
   * 审批通过
   */
  public approved(): void {
    showWarningConfirm(translation('tip.confirmApprovalSuccess'))
      .then(async () => {
        try {
          await installationOrderService.approved(this.id);
          this.installationOrder!.status = InstallationOrderStatusEnum.completed;
          Message.success(translation('operationRes.operationSuccess'));
          this.needReloadList = true;
          this.getInstallationInfo(this.id);
        } catch (error) {
          messageError(error as any);
        }
      })
      .catch(() => {
        Message.info(translation('operationRes.cancelOperation'));
      });
  }

  /**
   * 驳回
   */
  public async reject(): Promise<void> {
    MessageBox.prompt(translation('installationOrder.auditDescription'), {
      inputPlaceholder: translation('installationOrder.inputAuditDescription'),
      inputValidator: (value: string): boolean => {
        return !!value;
      },
      inputErrorMessage: translation('installationOrder.inputAuditDescription')
    })
      .then(async (data: MessageBoxData) => {
        try {
          await installationOrderService.reject({
            id: this.id,
            reason: (data as MessageBoxInputData).value
          });
          this.installationOrder!.status = InstallationOrderStatusEnum.inService;
          Message.success(translation('operationRes.operationSuccess'));
          this.needReloadList = true;
          this.getInstallationInfo(this.id);
        } catch (error) {
          messageError(error as any);
        }
      })
      .catch(() => {
        Message.info(translation('operationRes.cancelOperation'));
      });
  }

  @debounce()
  private getInstallationInfo(id: number): void {
    installationOrderService
      .getInfo(id)
      .then(res => {
        this.installationOrder = res;
      })
      .catch(error => {
        messageError(error);
      });
  }
  @debounce()
  private getExcept(id: number): void {
    installationOrderService
      .getExceptionList(id)
      .then(res => {
        this.exceptionList = res.data;
      })
      .catch(error => {
        messageError(error);
      });
  }
}
